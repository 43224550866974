<template>
  <div>
    <general-table
      ref="utilitiesTable"
      :api-url="APIURL"
      :add-type="addType"
      :add-component-name="addComponentName"
      :edit-component="editComponent"
      :delete-content="false"
      :view-content="false"
      :export-table="false"
      :type="type"
      :columns="columns"
      :guard="guard"
      :search-box="false"
      :selectable="false"
      :viw-component="viwComponent"
    >
      <template #import>
        <b-button
          variant="secondary"
          @click="openFileModel()"
        >
          <span class="text-nowrap">Import </span>
        </b-button>

      </template>
    </general-table>
    <b-modal
      id="file-modal"
      hide-footer
      centered
      title="Import Donations"
    >
      <validation-observer
        ref="infoRules"
      >
        <b-row>

          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              id="fileInput"
              label="Upload your file"
            >
              <b-form-file
                ref="refFile"
                plain
                @change="handleFileChange($event)"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
              v-if="!loader"
              variant="primary"
              class="mr-2"
              type="submit"
              @click="importDonation"
            >
              Add
            </b-button>
            <b-button
              variant="outline-danger"
              class="closeModal"
              @click="closeModal()"
            >
              Cancel
            </b-button>
            <b-button
              v-if="loader"
              variant="primary"
              disabled
              class="mr-1"
            >
              <b-spinner
                small
                type="grow"
              />
              Loading...
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>

import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import handleFormData from '@/global-compositions/handleFormData'
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  data() {
    return {
      APIURL: 'donations',
      addType: 'Add Donation',
      addComponentName: 'add-donation',
      editComponent: 'edit-donation',
      viwComponent: 'show-user',
      type: 'page',
      guard: 'donations',
      columns: [
        { key: 'id', sortable: true },
        { key: 'name' },
        { key: 'image' },
        { key: 'service_code' },
        { key: 'service_provider_code', label: 'service provider code' },
        { key: 'service_provider_name' },
        { key: 'short_code', label: 'Short code' },
        { key: 'actions' },
      ],

      loader: false,
      userForm: {},
      types: {},
      currentTime: '',
      role: '',
      selectedFile: '',
    }
  },
  setup() {
    const { formData, setFormData } = handleFormData()

    return {
      formData, setFormData,
    }
  },
  created() {

  },
  methods: {
    openFileModel() {
      this.$bvModal.show('file-modal')
    },
    closeModal() {
      this.$bvModal.hide('file-modal')
    },
    handleFileChange(event) {
      // eslint-disable-next-line prefer-destructuring
      this.selectedFile = event.target.files[0]
    },
    importDonation() {
      this.loader = true
      const formData = new FormData()

      formData.append('file', this.selectedFile)

      axios.post('import', formData).then(res => {
        if (res.status === 200 || res.status === 201) {
          this.closeModal()
          this.$router.push({ name: 'donations' })
          this.$toasted.show('Imported Successfully', {
            position: 'top-center',
            duration: 3000,
          })
        }
      }).catch(error => {
        if (error.response.status === 500) {
          this.$toast({
            component: ToastificationContent,
            props: {
              name: 'Ooops!!! An Unexpected Internal server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        } else if (error.response.status === 400) {
          this.errorMsg = error.response.data.error
        }
      }).finally(() => {
        this.loader = false
      })
    },
  },
}
</script>

<style scoped>
.change-status {
  cursor: pointer;
}
</style>
